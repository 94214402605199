


























import type { Picture, Link } from '@/inc/types'

import { defineComponent, PropType, ref } from '@vue/composition-api'
import { pushCta, GtmCta } from '@/inc/utils'

interface CTAJobs {
  title: string
  htmltext: string
  number: number
  link: Link
  picture: Picture
  ctaJobs?: CTAJobs
}

export default defineComponent({
  name: 'CtaJobs',
  components: {},
  props: {
    content: {
      type: Object as PropType<CTAJobs>,
      required: true,
    },
  },

  setup(props) {
    const rootEl = ref<HTMLElement | null>(null)
    const ctaContent = props.content.ctaJobs || props.content
    const text = ctaContent.htmltext?.replace(
      '{{number}}',
      `<strong>${ctaContent.number}</strong>`
    )

    // Push event to datalayer on click
    const onClick = () => {
      if (!rootEl.value) {
        return
      }

      const layer: Partial<GtmCta> = {
        ctaLabel: ctaContent.link.label,
        ctaType: 'jobs',
        ctaUrl: ctaContent.link.url,
      }

      pushCta(layer, rootEl.value)
    }

    return { rootEl, ctaContent, text, onClick }
  },
})
